import {usePersonsStore} from "../stores/usePersonsStore";
import {IPersonDbEntity} from "../../types/supabase/persons/IPersonDbEntity";
import {useCurrentEvent} from "../filters/events/useCurrentEvent";
import {SupabaseCrudActions} from "../../utils/SupabaseCrudActions";
import {ICrudActions} from "../../types/utils/ICrudActions";
import {PersonSupabaseClient} from "../../clients/PersonSupabaseClient";

export const usePersonActions = (): ICrudActions<IPersonDbEntity> => {

  const event = useCurrentEvent();
  const store = usePersonsStore();

  return new SupabaseCrudActions<IPersonDbEntity>(
    store, new PersonSupabaseClient(),
    () => ({
      event_id: event!.id,
      given_name: '',
      family_name: '',
      hourly_wage: 15,
    } as IPersonDbEntity)
  );
};
