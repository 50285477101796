import React, {FC, useEffect} from 'react';
import './App.css';
import {AppBar} from "../layout/app-bar/AppBar";
import {PageRouter} from "../pages/PageRouter";

import {useSupabaseSession} from "../hooks/supabase/useSupabaseSession";
import {useEventActions} from "../hooks/actions/useEventActions";
import {useAuthStore} from "../hooks/stores/useAuthStore";
import {AppDrawer} from "../layout/app-drawer/AppDrawer";
import {usePersonActions} from "../hooks/actions/usePersonActions";
import {useTeamActions} from "../hooks/actions/useTeamActions";
import {useAreaActions} from "../hooks/actions/useAreaActions";
import {useRadioChannelActions} from "../hooks/actions/useRadioChannelActions";
import {useTeamMemberActions} from "../hooks/actions/useTeamMemberActions";
import {useCurrentEvent} from "../hooks/filters/events/useCurrentEvent";
import {useEventsStore} from "../hooks/stores/useEventsStore";
import {useAppActions} from "../hooks/app/useAppActions";
import {useEventAdminActions} from "../hooks/actions/useEventAdminActions";
import {SnackbarProvider} from "notistack";
import {useAccountActions} from "../hooks/actions/useAccountActions";
import {useAccountCardActions} from "../hooks/actions/useAccountCardActions";

const App: FC = () => {

  useSupabaseSession();

  const {session} = useAuthStore();

  const {items: events} = useEventsStore()
  const currentEvent = useCurrentEvent();
  const {setCurrentEventId} = useAppActions();

  const eventActions = useEventActions();
  const personActions = usePersonActions();
  const teamActions = useTeamActions();
  const areaActions = useAreaActions();
  const radioChannelActions = useRadioChannelActions();
  const teamMemberActions = useTeamMemberActions();
  const eventAdminActions = useEventAdminActions();
  const accountActions = useAccountActions();
  const accountCardActions = useAccountCardActions();

  useEffect(() => {

    if (!(session?.user.id))
      return;

    setTimeout(async () => {
      await Promise.all([
        radioChannelActions.fetchAll(),
      ]);
    }, 5);

    setTimeout(async () => {
      await Promise.all([
        personActions.fetchAll(),
        teamActions.fetchAll(),
        teamMemberActions.fetchAll(),
      ]);
    }, 300);

    setTimeout(async () => {
      await Promise.all([
        eventAdminActions.fetchAll(),
        eventActions.fetchAll(),
        areaActions.fetchAll(),
        accountActions.fetchAll(),
        accountCardActions.fetchAll(),
      ]);
    }, 500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session?.user.id]);

  useEffect(() => {
    if (events.length === 0)
      return;

    if (currentEvent !== null)
      return;

    const defaultEvent = events[0];
    setCurrentEventId(defaultEvent.id);
  }, [currentEvent, events, setCurrentEventId]);

  return (
    <>

      <AppBar />

      <SnackbarProvider>

        <PageRouter />

      </SnackbarProvider>

      <AppDrawer />
    </>
  );
}

export default App;
